





























import Vue, { PropType } from 'vue'
import * as CmsApi from '~/api/cms'
import { cmsImageToSources } from '~/api/cms'

export default Vue.extend({
  name: 'AuthSignOnConfirm',
  props: {
    trainerDetail: {
      type: Object as PropType<CmsApi.CmsTrainerDetailWithQuotesResponse[0]>,
      required: true,
    },
  },
  methods: {
    cmsImageToSources,
  },
})
