import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5ae977fb&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=5ae977fb&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae977fb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BSGHeading: require('/workspace/styleguide/components/Heading.vue').default,BSGInput: require('/workspace/styleguide/components/Input.vue').default,BSGButton: require('/workspace/styleguide/components/Button.vue').default,BSGIcon: require('/workspace/styleguide/components/Icon.vue').default,BSGButtonGroup: require('/workspace/styleguide/components/ButtonGroup.vue').default})
