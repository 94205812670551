














































import Vue, { PropType } from 'vue'

type CodeError = 'failed' | 'network' | undefined

export default Vue.extend({
  name: 'AuthCode',
  props: {
    mail: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      codeInput: '',
      code: '',
      loading: false,
      error: undefined as CodeError,
      resend: false,
      resendLoading: false,
    }
  },
  computed: {
    errorMessage(): string {
      return this.error
        ? (this.$t(`AUTH_CODE_ERROR_${this.error.toUpperCase()}`) as string)
        : ''
    },
  },
  methods: {
    input(event: InputEvent) {
      const target = event?.target as HTMLInputElement
      if (target !== null) {
        this.codeInput = target.value
        this.onChange()
      }
    },
    onChange() {
      const strippedCode = this.codeInput.replaceAll(/(\s|-)/g, '')
      if (strippedCode.length === 8) this.confirm(strippedCode)
    },
    async retry() {
      this.loading = true
      try {
        await this.$vuex.dispatch('auth/signIn', { mail: this.mail })
        this.resend = true

        setTimeout(() => {
          this.resend = false
        }, 3000)
      } catch {
        // no error handling
      }
      this.loading = false
    },
    async confirm(code: string) {
      if (code && code.length < 8) {
        this.error = 'failed'
        return
      }

      this.loading = true
      this.code = code.trim().toUpperCase()

      try {
        await this.$vuex.dispatch('auth/code', {
          code: this.code,
        })
        this.error = undefined
        this.$emit('complete')
      } catch (e: unknown) {
        const ex = e as { type?: string }
        if (ex.type && ex.type === 'failed') this.error = 'failed'
        else this.error = 'network'
      }
      this.loading = false
    },
    cancel() {
      this.$emit('cancel')
    },
  },
})
